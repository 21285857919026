import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const auth = createSlice({
    name: 'auth',
    initialState: {
        auth: false,
        check: false,
        token: null,
    },
    reducers: {
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        setCheck: (state, action) => {
            state.check = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
    }
})

export const { setAuth, setCheck, setToken } = auth.actions

export const checkAuth = () => (dispatch, getState) => {
    let tokenLocal = getLocalStorage("token")

    if (tokenLocal === null || typeof tokenLocal === "undefined") {
        dispatch(setCheck(true))
        dispatch(setAuth(false))
        return
    }
    let now = new Date()
    let expToken = JSON.parse(atob(tokenLocal.split('.')[1]))

    if (now.getTime() > expToken.exp * 1000) {
        dispatch(logOut())
        return
    }

    dispatch(setCheck(true))
    dispatch(setAuth(true))
}

export const logOut = () => dispatch => {
    setLocalStorage('token', null)
    dispatch(setToken(null))
    dispatch(setAuth(false))
    dispatch(setCheck(true))
}

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}

const getLocalStorage = (key) => {
    const value = localStorage.getItem(key)
    if (value === "" || value === "null" || value === "undefind") return null
    return value
}

const okLogin = data => dispatch => {
    setLocalStorage('token', data.token)
    dispatch(setToken(data.token))
    dispatch(setAuth(true))
    dispatch(setCheck(true))
}

export const loginStore = (data, navigate, path) => dispatch => {
    let config = {
        method: 'post',
        url: `${gatewayURL}/login`,
        headers: { 
            'Content-Type': 'application/json', 
        },
        data : data
    };

    axios.request(config)
        .then((response) => {
            dispatch(okLogin(response.data.result))

            if (path === "/login") {
                navigate("/app")
            }
        })
        .catch((error) => {
            console.log(error)
        });
}

export default auth.reducer