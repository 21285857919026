import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQueuesData } from "../../store/QueuesStore/queues"
import { useNavigate } from "react-router-dom"

const QueuesPage = () => {

    const dispatch = useDispatch()
    
    const data = useSelector((state) => state.queues.data)

    useEffect(() => {
        dispatch(getQueuesData())
    }, [dispatch])


    if (data === null) {
        return (
            <>LOAD...</>
        )
    }

    return (
        <>
            <h1>Очереди работ</h1>
            {data.map((e, i) => <Service key={i}  data={e} />)}
        </>
    )
}

export default QueuesPage

const Service = ({data}) => {
    const navigate = useNavigate()

    return (
        <div style={{display: "flex", alignItems: "center", marginBottom: 10, cursor: "pointer"}} onClick={() => navigate(data.service.code)}>
            <div className={data.errors === 0 ? "dot yes mr-10" : "dot no mr-10"}></div>
            {data.service.name} - {data.update}
        </div>
    )
}