import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPressureData } from "../../../store/ServicesStore/pressure"


const Pressure = () => {
    return (
        <div>
            <h2>Нагрузка</h2>
            <PressureView />
        </div>
    )
}

export default Pressure

const PressureView = () => {
    const dispatch = useDispatch()
    const pressure = useSelector((state) => state.pressure.services)

    const [m, setM] = useState(0)

    useEffect(() => {
        dispatch(getPressureData())
    }, [dispatch])

    useEffect(() => {
        if (pressure && pressure.length > 0) {
            pressure.forEach(element => {
                if (element.Count > m) {
                    setM(element.Count)
                }
            });
        }
    }, [pressure, m])

    if (!pressure) {
        return (
            <div>Error</div>
        )
    }

    if (pressure.length === 0) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div>
            {pressure.map((e, i) => <PressureRow key={i} data={e} max={m} />)}
        </div>
    )
}

const PressureRow = ({data, max}) => {
    const [w, setW] = useState(0)

    useEffect(() => {
        if (max !== 0 && data.Count > 0) {
            setW(data.Count * 100 / max)
        }
    }, [max, data.Count])

    return (
        <div>
            <div className="pressure-text">{data.Service} - {data.Count}</div>
            <div className="pressure-row" style={{width: w * 5}}></div>
        </div>
    )
}