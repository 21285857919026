import Ping from "./Ping"
import Pressure from "./Pressure"

const ServicesPage = () => {
    return (
        <div>
            <h1>Сервисы</h1>
            <div>
                <Pressure />
                <Ping />
            </div>
        </div>
    )
}

export default ServicesPage