import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const Queues = createSlice({
    name: 'Queues',
    initialState: {
        data: null,
        log: null,
        line: null
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        },
        setQueuelog: (state, action) => {
            state.log = action.payload
        },
        setQueueLine: (state, action) => {
            state.line = action.payload
        }
    }
})

export const { setData, setQueuelog, setQueueLine } = Queues.actions

export const getQueuesData = () => dispatch => {
    let config = {
        method: 'get',
        url: `${gatewayURL}/queues`,
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    axios.request(config)
    .then((response) => {
        dispatch(setData(response.data.result))
    })
    .catch((error) => {
        console.log(error)
    });
}

export const getQueueLog = (service) => dispatch => {
    let config = {
        method: 'get',
        url: `${gatewayURL}/queues/${service}`,
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    axios.request(config)
    .then((response) => {
        dispatch(setQueuelog(response.data.result))
    })
    .catch((error) => {
        console.log(error)
    });
}

export const getQueueLine = (id, service) => dispatch => {
    let config = {
        method: 'get',
        url: `${gatewayURL}/queues/${service}/${id}/line`,
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    axios.request(config)
    .then((response) => {
        dispatch(setQueueLine(response.data.result))
    })
    .catch((error) => {
        console.log(error)
    });
}

export const resetQueueError = (service, ID) => dispatch => {
    let config = {
        method: 'put',
        url: `${gatewayURL}/queues/${service}/${ID}`,
        headers: { 
            'Content-Type': 'application/json',
        },
        data: {
            reset: true
        }
    };

    axios.request(config)
    .then((response) => {
//        dispatch(setLine(response.data.result))
        console.log(response.data)
    })
    .catch((error) => {
        console.log(error)
    });
}

export default Queues.reducer
