import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'

export const Services = createSlice({
    name: 'Services',
    initialState: {
        services: []
    },
    reducers: {
        setServicesList: (state, action) => {
            state.services = action.payload
        }
    }
})

export const { setServicesList } = Services.actions

export const getServicesList = () => dispatch => {
    let config = {
        method: 'get',
        url: `${gatewayURL}/services`,
        headers: { 
            'Content-Type': 'application/json',
        }
    };

    axios.request(config)
    .then((response) => {
        dispatch(setServicesList(response.data.result))
    })
    .catch((error) => {
        console.log(error)
    });
}

export default Services.reducer