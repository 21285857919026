import { Provider } from "react-redux";
import store from "./store/store";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import LoggerPage from "./pages/LoggerPage";
import Wrapper from "./components/Wrapper";
import ServicesPage from "./pages/ServicesPage";
import QueuesPage from "./pages/QueuesPage";
import ServiceQueue from "./pages/QueuesPage/ServiceQueue";

const Layout = () => {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomePage />,
        errorElement: <ErrorPage />
    },
    {
      path: '/logger',
      element: <LoggerPage />,
      errorElement: <ErrorPage />
    },
    {
      path: '/services',
      element: <ServicesPage />,
      errorElement: <ErrorPage />
    },
    {
      path: '/queues',
      element: <QueuesPage />,
      errorElement: <ErrorPage />
    },
    {
      path: '/queues/:service',
      element: <ServiceQueue />,
      errorElement: <ErrorPage />
    },
    ]
  },
])

const App = () => {
  return (
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
