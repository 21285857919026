import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getQueueLine, resetQueueError } from "../../store/QueuesStore/queues"

const QueueDetails = ({log, service}) => {

    const dispatch = useDispatch()

    const line = useSelector((state) => state.queues.line)

    useEffect(() => {
        dispatch(getQueueLine(log.id, service))
    }, [dispatch, log, service])

    return (
        <div>
            <h2>Информация</h2>
            <table className="table-details">
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{log.id}</td>
                    </tr>
                    <tr>
                        <td>Компания</td>
                        <td>{log.company_id}</td>
                    </tr>
                    <tr>
                        <td>Статус</td>
                        <td>{log.status}</td>
                    </tr>
                    <tr>
                        <td>Создана</td>
                        <td>{log.created}</td>
                    </tr>
                    <tr>
                        <td>Последнее обновление</td>
                        <td>{log.updated}</td>
                    </tr>
                </tbody>
            </table>
            <div style={{width: 200, margin: '20px 0px'}}>
                <button onClick={() => dispatch(resetQueueError(service, log.id))}>Сбросить ошибку</button>
            </div>

            <Line />
    
        </div>
    )
}

export default QueueDetails

const Line = () => {
    const line = useSelector((state) => state.queues.line)

    if (!line) return null
    return (
        <>
        
        <h2>Связанные логи</h2>

            <div className="line" style={{height: line.length * 30}}>
                {line.map((e, i) => <Span key={i} span={e} index={i} firstStart={line[0].Time} />)}
            </div>
            </>
    )
}

const Span = ({span, index, firstStart}) => {
    return (
        <div className="span-line" 
                style={{
                    top: index * 30, 
                    width: span.Duration / 1000,
                    backgroundColor: span.Level === "ERROR" ? "red" : "green", 
                    left: (span.Time - firstStart) / 1000 }}>
            <div className="span-line-label">{span.Resource} {'->'} {span.Service} {span.Message} {span.Duration / 1000000}</div>
        </div>
    )
}