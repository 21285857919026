import { useNavigate } from "react-router-dom"

const menuItems = [
    {
        label: "Главная",
        path: "/"
    },
    {
        label: "Логи",
        path: "/logger"
    },
    {
        label: "Состояние сервисов",
        path: "/services"
    },
    {
        label: "Очереди",
        path: "/queues"
    },
    {
        label: "Настройки",
        path: "/settings"
    },
    {
        label: "Пользователи",
        path: "/users"
    }
]

const Menu = () => {
    const navigate = useNavigate()

    return (
        <div>
            <ul className="main-menu">
                {menuItems.map((e, i) => <li key={i} onClick={() => navigate(e.path)}>{e.label}</li>)}
            </ul>
        </div>
    )
}

export default Menu