import { configureStore } from '@reduxjs/toolkit'
import logger from './LogStore/logger'
import pressure from './ServicesStore/pressure'
import ping from './ServicesStore/ping'
import  auth  from './AuthStore/auth'
import queues from './QueuesStore/queues'
import services from './ServicesStore/services'

export default configureStore({
	reducer: {
        logger,
        pressure,
        ping,
        auth,
        queues,
        services
    },
})