import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { checkAuth } from "../../store/AuthStore/auth"
import LoginPage from "../../pages/LoginPage"

const AuthComponent = ({children}) => {

    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth.auth)
    const check = useSelector((state) => state.auth.check)

    useEffect(() => {
        dispatch(checkAuth())
        setInterval(() => {
            dispatch(checkAuth())
        }, 10000)
    }, [dispatch])

    if (!check) {
        return <>LOAD...</>
    }

    if (!auth) {
        return (
            <LoginPage/>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default AuthComponent