import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPingData } from "../../../store/ServicesStore/ping"

const Ping = () => {

    const dispatch = useDispatch()
    const ping = useSelector((state) => state.ping.services)

    useEffect(() => {
        dispatch(getPingData())
    }, [dispatch])

    if (!ping) {
        return (
            <div>Error</div>
        )
    }

    if (ping.length === 0) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div>
            <h2>Пинг</h2>
            {ping.map((e, i) => <PingRow key={i} data={e} />)}
        </div>
    )
}

export default Ping

const PingRow = ({data}) => {
    return (
        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
            <div className={data.Ping ? "dot yes mr-10" : "dot no mr-10"}></div>
            {data.Service}
        </div>
    )
}