import AuthComponent from "../Auth"
import Menu from "./Menu"

const Wrapper = ({children}) => {
    return (
        <AuthComponent>
            <div className="wrapper">
                <Menu />
                {children}
            </div>
        </AuthComponent>
    )
}

export default Wrapper